import imglit14 from "./images/ukrlit/imglit_14.png"; 
import imglit15 from "./images/ukrlit/imglit_15.png"; 
import imglit16 from "./images/ukrlit/imglit_16.png"; 



export const module39_41 = {
  title: "§ 39-41. Проблематика п’єси Михайла Старицького «За двома зайцями»",
  questions: [
    {
      id: 1,
      question: "1. Михайло Старицький – один із представників",
      options: [
        { id: "А", text: "Харківської школи романтиків" },
        { id: "Б", text: "товариства «Руська бесіда»" },
        { id: "В", text: "аматорського театру" },
        { id: "Г", text: "театру корифеїв" }
      ],
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 2,
      question: "2. НЕПРАВИЛЬНИМ є твердження, що комедія «За двома зайцями»",
      options: [
        { id: "А", text: "має ознаки водевілю" },
        { id: "Б", text: "складається з трьох дій" },
        { id: "В", text: "є переробкою твору Івана Нечуя-Левицького" },
        { id: "Г", text: "містить як прозові, так і віршові репліки героїв" }
      ],
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 3,
      question: "3. Події комедії «За двома зайцями» відбуваються в місті, позначеному на карті цифрою",
      options: [
        { id: "А", text: "1" },
        { id: "Б", text: "2" },
        { id: "В", text: " 3 " },
        { id: "Г", text: "4" }
      ],
      imgPath: imglit14,
      addClass: "horizontal",
      correctAnswer: "Б",
      isTest: true,
    },
    {
      id: 4,
      question: "4. Слова <i>«Мінє так солодке обридло! Кожинного дня у нас вдома ласощов етих разних, хоч свиней годуй! Я ще больше люблю пальцини, нанаси...»</i> належать героєві / героїні, що позначений / позначена на ілюстрації цифрою",
      options: [
        { id: "А", text: "1" },
        { id: "Б", text: "2" },
        { id: "В", text: "3" },
        { id: "Г", text: "4" }
      ],
      imgPath: imglit15,
      addClass: "vertical",
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 5,
      question: "5. Кадр із кінофільму «За двома зайцями» передає його ",
      options: [
        { id: "А", text: "зав’язку" },
        { id: "Б", text: "розвиток дії" },
        { id: "В", text: "кульмінацію" },
        { id: "Г", text: "розв’язку" }
      ],
      imgPath: imglit16,
      addClass: "horizontal",
      correctAnswer: "Г",
      isTest: true,
    },
    {
      id: 6,
      question: '6. Розподіліть літературних героїв / героїнь за творами: <i><b>Комедія «За двома зайцями»; Комедія «Сватання на Гончарівці».</b></i> <br>(Перетягніть імена у відповідний кошик)',
      isGame: true,
      initialWords: [
        { id: 1, text: "Прокіп Свиридович Сірко", type: "leftBucket" },
        { id: 2, text: "Прокіп Шкурат", type: "rightBucket" },
        { id: 3, text: "Проня", type: "leftBucket" },
        { id: 4, text: "Уляна", type: "rightBucket" },
        { id: 5, text: "Свирид Петрович Голохвостий", type: "leftBucket" },
        { id: 6, text: "Осип Скорик", type: "rightBucket" },
        { id: 7, text: "Стецько", type: "rightBucket" },
        { id: 8, text: "Секлита Пилипівна Лимариха", type: "leftBucket" }
      ],
      leftBucketTitle: "Комедія «За двома зайцями»",
      rightBucketTitle: "Комедія «Сватання на Гончарівці»"
    },
    {
      id: 7,
      question:
        "7. Перегляньте відеоматеріал телеканалу «Київ24» про головну героїню фільму «За двома зайцями» Проню Прокопівну (5 хв 22 с) й візьміть участь у грі «Так – ні»." ,
      videoPath:
        "https://www.youtube.com/embed/WYFtei40xos?si=kfE5iRYiUJ85qjgd",
      addClass: "horizontal",
      isTest: true,
      isCreativeTask: true,
      
    },
    {
      id: 8,
      question: '8. Гра «Так – ні» про головну героїню фільму «За двома зайцями» Проню Прокопівну: <i><b>Розподіліть твердження за відповідями «Так» та «Ні».</b></i> <br>(Перетягніть твердження у відповідний кошик)',
      isGame: true,
      initialWords: [
        { id: 1, text: "Матеріал знято до 60-річчя головної героїні фільму «За двома зайцями».", type: "rightBucket" },
        { id: 2, text: "Репортаж трансльовано з Подільського району міста Києва.", type: "leftBucket" },
        { id: 3, text: "У первинній версії фільму «За двома зайцями» герої розмовляють російською мовою.", type: "rightBucket" },
        { id: 4, text: "Українськомовну версію фільму «За двома зайцями» знайшли в Маріупольському кінофонді.", type: "leftBucket" },
        { id: 5, text: "Пам’ятник Проні Прокопівні та Голохвостому встановлено на Хрещатику.", type: "rightBucket" },
        { id: 6, text: "Дочка актриси, яка зіграла роль Проню Прокопівну, у захваті від ідеї озвучення пам’ятника.", type: "rightBucket" },
        { id: 7, text: "Згідно з київською традицією, щоб була удача, потрібно потерти жука-рогоносця, що сидить на сюртуку Голохвостого, та капелюшок Проні Прокопівни.", type: "rightBucket" },
        { id: 8, text: "Репортаж присвячено відкриттю пам’ятника героям комедії «За двома зайцями».", type: "rightBucket" },
        { id: 9, text: "Репортаж ведеться в прямому ефірі.", type: "leftBucket" },
        { id: 10, text: "Окремі сцени фільму «За двома зайцями» знімали біля Андріївської церкви.", type: "leftBucket" }
      ],
      leftBucketTitle: "ТАК",
      rightBucketTitle: "НІ"
    },





    // Додати нові питання сюди
  ],
};
